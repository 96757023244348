import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-litigio-estrategico',
  templateUrl: './litigio-estrategico.component.html',
  styleUrls: ['./litigio-estrategico.component.scss']
})
export class LitigioEstrategicoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
