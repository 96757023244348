import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-laboral',
  templateUrl: './laboral.component.html',
  styleUrls: ['./laboral.component.scss']
})
export class LaboralComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
