import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-derechos-migratorios',
  templateUrl: './derechos-migratorios.component.html',
  styleUrls: ['./derechos-migratorios.component.scss']
})
export class DerechosMigratoriosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
